<template>
  <section class="card p-4">
    <div class="row">
      <div class="col-12" align="center">
        <button @click="front3" class="where-by-button">3ตัวหน้า</button>
        <button @click="back3" class="where-by-button">3ตัวหลัง</button>
        <button @click="back2" class="where-by-button">2ตัวหลัง</button>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="inputA">
        <form>
            <input v-model="searchData[0]" :disabled="searchData[0]=='*'?true:false" pattern="^[0-9]*$" autocomplete="off" :class="`pin-input ${searchData[0]=='*'?'input-empty':''}`" type="text" maxlength="1" />
            <input v-model="searchData[1]" :disabled="searchData[1]=='*'?true:false" pattern="^[0-9]*$" autocomplete="off" :class="`pin-input ${searchData[1]=='*'?'input-empty':''}`" type="text" maxlength="1" />
            <input v-model="searchData[2]" :disabled="searchData[2]=='*'?true:false" pattern="^[0-9]*$" autocomplete="off" :class="`pin-input ${searchData[2]=='*'?'input-empty':''}`" type="text" maxlength="1" />
            <input v-model="searchData[3]" :disabled="searchData[3]=='*'?true:false" pattern="^[0-9]*$" autocomplete="off" :class="`pin-input ${searchData[3]=='*'?'input-empty':''}`" type="text" maxlength="1" />
            <input v-model="searchData[4]" :disabled="searchData[4]=='*'?true:false" pattern="^[0-9]*$" autocomplete="off" :class="`pin-input ${searchData[4]=='*'?'input-empty':''}`" type="text" maxlength="1" />
            <input v-model="searchData[5]" :disabled="searchData[5]=='*'?true:false" pattern="^[0-9]*$" autocomplete="off" :class="`pin-input ${searchData[5]=='*'?'input-empty':''}`" type="text" maxlength="1" />
        </form>
      </div>
    </div>
    <div class="row justify-content-center" align="center">
      <div class="buttonA">
        <button @click="clear" class="edit-button mb-0">แก้ไข</button>
        <button @click="find" class="where-by-button-find mb-0">ค้นหา</button>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'SearchLotto',
  props: [
    
  ],
  data: function () {
    return {
      searchData:[
        '',
        '',
        '',
        '',
        '',
        ''
      ]
    }
  },
  mounted() {
    this.startInput()
  },
  methods: {
    async startInput(){
      $(".pin-input").keyup(function() {
          if($(this).val().length >= 1) {
            var input_flds = $(this).closest('form').find(':input');
            input_flds.eq(input_flds.index(this) + 1).focus();
          }
      });
      $(".pin-input").keydown(function() {
          $(this).val('')
      });
    },
    async front3(){
      this.searchData[0]=''
      this.searchData[1]=''
      this.searchData[2]=''
      this.searchData[3]='*'
      this.searchData[4]='*'
      this.searchData[5]='*'
    },
    async back3(){
      this.searchData[0]='*'
      this.searchData[1]='*'
      this.searchData[2]='*'
      this.searchData[3]=''
      this.searchData[4]=''
      this.searchData[5]=''
    },
    async back2(){
      this.searchData[0]='*'
      this.searchData[1]='*'
      this.searchData[2]='*'
      this.searchData[3]='*'
      this.searchData[4]=''
      this.searchData[5]=''
    },
    async clear(){
      this.searchData = this.searchData.map(x => x='');
    },
    async find(){
      try {
        let getSearch = this.searchData.join("");
        if(getSearch=='******'){
          return
        }
        this.$emit('SearchLottoReturnData',getSearch);
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    }

  },
  computed: {

  },
  watch:{
 

  }
  
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
$main-color:#FFC700;
@mixin btn-where-by{
  margin-right: 5px;
  margin-bottom: 15px;
  background-color: $main-color;
  border: none;
}
.pin-input {
  padding: 10px;
  border: 1px solid #ddd;
  width: 10vmin;
  height: 10vmin;
  text-align: center;
  font-size: clamp(1rem, 5vmin, 2rem);
  margin-right: 5px;
  margin-bottom: 15px;
  padding-top: 0px;
  padding-right: 0px;
  padding-bottom: 0px;
  padding-left: 0px;
  max-width: 50px;
  max-height: 50px;
}
.input-empty {
  background-color: $main-color;
}
.edit-button{
  @include btn-where-by;
  width: 30%;
  
}
.where-by-button{
  @include btn-where-by;
  width: 200px;
  padding: 15px;
  
}
.where-by-button-find{
  @include btn-where-by;
  width: 70%;
}
.buttonA{
  align-items: center;
  white-space: nowrap;
  width: 100%;
}
</style>
