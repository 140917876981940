<template>
  <div class="row w-200">
    <div class="col-12">
      <div v-if="!(countData==0)" class="row relative px-5">
        <div v-for="listLotto in listLottoHaveMaxQty" :key="listLotto.lottoNumber" class="col-12 col-md-3 my-2 pt-3">
          <p class="text-center text-white m-0">หมายเลข {{listLotto.lottoNumber}}</p>
            <img @click="selectedLotto(listLotto)" class="img-lotto" :src="listLotto.imageFullRes" :alt="listLotto.lottoRefNumber">
          <p class="text-center m-0 text-white">เหลือ {{listLotto.qty}} ใบ</p>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-12">
          <h3 class="text-center">ไม่พบเลขที่ต้องการ</h3>
        </div>
      </div>
      
    </div>
    <Modal v-if="showModal" @close="showModal = false">
        <template v-slot:body>
          <div class="row d-none d-lg-block p-3">
            <div class="col-12">
              <img class="logo-head-left" src="/images/numchok.png" alt="numchok-logo">
              <p class="h1 text-right">รวมแจ๊คพอต 18 ล้านบาท</p>
            </div>
          </div>
          <div class="row d-sm-block d-lg-none d-lg-none p-3">
            <div class="col-12">
              <img class="logo-head-left" src="/images/numchok.png" alt="numchok-logo">
              <p class="h1 text-center pt-5">รวมแจ๊คพอต 18 ล้านบาท</p>
            </div>
          </div>
          <div class="row  bg-gradient-red-orange position-relative">
            <div class="col-12 py-3 p-md-5 ">
              <div class="row ">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <h3 class="text-white" >หมายเลข {{selectedLottoModal.lottoNumber}}</h3>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <img class="image-modal" :src="selectedLottoModal.imageFullRes" alt="">
                  </div>
                </div>
              </div>
              <div class="row d-none d-md-block mt-2">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <a @click="minusModal" class="btn-minus" >-</a>
                    <input @change="qtyOnChang" v-model="selectedLottoModal.qty" class="input-qty" type="text">
              
                    <a @click="plusModal" class="btn-plus">+</a>
                    <a @click="wantMax" class="btn-fill ml-2">ทั้งหมด</a>
                  </div>
                </div>
              </div>
              <div class="row d-none d-md-block mt-2">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <a @click="addtocart" class="btn-fill px-5 ml-2">ใส่ตะกร้า</a>
                  </div>
                </div>
              </div>

              <div class="row d-sm-block d-md-none d-lg-none mt-2">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <a @click="minusModal" class="btn-minus" >-</a>
                    <input @change="qtyOnChang" v-model="selectedLottoModal.qty" class="input-qty w-100" type="text">
                    <a @click="plusModal" class="btn-plus">+</a>
                  </div>
                </div>
              </div>
              <div class="row d-sm-block d-md-none d-lg-none mt-2">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <a @click="wantMax" class="btn-fill w-100 text-center">ทั้งหมด</a>
                  </div>
                </div>
              </div>
              <div class="row d-sm-block d-md-none d-lg-none mt-2">
                <div class="col-12">
                  <div class="d-flex justify-content-center">
                    <a @click="addtocart" class="btn-fill w-100 text-center ">ใส่ตะกร้า</a>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </template>
      </Modal>
  </div>
</template>

<script>
import * as serviceMain  from '@/services/main.service';
import Modal from '@/components/Modal.vue'
export default {
  name: 'ListLotto',
  components: {
   
    Modal
  },
  props: [
    "start",
    "length",
    "searchNumber",
    "lottoType"
  ],
  data: function () {
    return {
      showModal: false,

      limitData:4,
      countData:0,
      startData:0,
      endData:0,
      offsetData:0,
      pageTotal:0,
      selectedPageData:1,

      searchNumberData:'',
      lottoTypeData:'',
      listLottoData:[],
      selectedLottoModal:{   
        drawDate: "",
        drawNumber: "",
        setNumber: "",
        lottoNumber: "",
        lottoBarcode: "",
        lottoRefNumber: "",
        price: "", 
        serviceFee: "",
        qty: 2,
        image: "",
        qtyMax:1
      }
    }
  },
  mounted() {
    this.startSetProp()
  },
  methods: {
//listData start
    async startSetProp(){
      this.offsetData=parseInt(this.start)
      this.limitData=parseInt(this.length)
      this.searchNumberData=this.searchNumber
      this.lottoTypeData=this.lottoType
      this.call_api_count_lotto()
    },
    async call_api_count_lotto(){
      try {
        let data = {
            start:this.offsetData,
            length:this.limitData,
            searchNumber:this.searchNumberData,
            lottoType:this.lottoTypeData
        }

        let getAPI = await this.$call_api('get','lotto/api/v1/listLotto',data,'auth');
        this.countData = getAPI.data.recordsFiltered;
        this.startPagination()
      } catch (error) {
        // console.log(error)
        if(error.code=="0001"){
          error.messageTH ='กรุณาใส่หมายเลขที่ต้องการ'
        }
        serviceMain.showErrorAlert(this,error)
      }
    },
    async call_api_lotto(){
      try {
        let data = {
            start:this.offsetData,
            length:this.limitData,
            searchNumber:this.searchNumberData,
            lottoType:this.lottoTypeData
        }

        let getAPI = await this.$call_api('get','lotto/api/v1/listLotto',data,'auth');
        // this.countData = getAPI.data.recordsFiltered;
        this.listLottoData = getAPI.data.data;
      } catch (error) {
        serviceMain.showErrorAlert(this,error)
      }
    },
    
    startPagination(){
      let getCount = this.countData;
      let getlimit = this.limitData;
      let pageTotal = Math.ceil(getCount/getlimit);
      this.pageTotal = pageTotal;
      this.startData = (this.selectedPageData*this.limitData)-this.limitData+1;
      this.endData = this.selectedPageData*this.limitData
      this.offsetData = this.startData-1;
      if(this.endData>getCount){
        this.endData = getCount
      }
      if(this.offsetData>this.Count){
        this.offsetData = this.Count-1
        this.startData = this.Count
      }
      this.call_api_lotto()
    },
    next(){
      if(this.selectedPageData >=this.pageTotal){
        this.selectedPageData =this.pageTotal
        return
      }
      this.selectedPageData =this.selectedPageData+1
      this.startPagination();
    },
    prev(){
      if(this.selectedPageData <=1){
        this.selectedPageData =1
        return
      }
      this.selectedPageData =this.selectedPageData-1
      this.startPagination();
    },
    async selectedLotto(e){
      this.selectedLottoModal={   
        drawDate: e.drawDate,
        drawNumber: e.drawNumber,
        setNumber: e.setNumber,
        lottoNumber: e.lottoNumber,
        lottoBarcode: e.lottoBarcode,
        lottoRefNumber: e.lottoRefNumber,
        imageFullRes: e.imageFullRes,
        price: e.price,
        serviceFee: e.serviceFee,
        qty: e.qty,
        image: e.image,
        qtyMax: e.qtyMax
      }
      this.showModal=true 
    },
    async plusModal(){
      this.selectedLottoModal.qty += 1;
      if(this.selectedLottoModal.qty>this.selectedLottoModal.qtyMax){
        this.selectedLottoModal.qty=this.selectedLottoModal.qtyMax;
      }
    },
    async minusModal(){
      this.selectedLottoModal.qty -= 1;
      if(this.selectedLottoModal.qty<1){
        this.selectedLottoModal.qty=1;
      }
    },
    async qtyOnChang(){
      if(this.selectedLottoModal.qty>this.selectedLottoModal.qtyMax){
        this.selectedLottoModal.qty=this.selectedLottoModal.qtyMax;
      }
      if(this.selectedLottoModal.qty<1){
        this.selectedLottoModal.qty=1;
      }
    },
    wantMax(){
      this.selectedLottoModal.qty = this.selectedLottoModal.qtyMax
    },
    async addtocart(){
      let getStateCart = this.getCART;
      getStateCart =getStateCart.filter(e => e.lottoNumber !==this.selectedLottoModal.lottoNumber);
      getStateCart.push({   
        drawDate: this.selectedLottoModal.drawDate,
        drawNumber: this.selectedLottoModal.drawNumber,
        setNumber: this.selectedLottoModal.setNumber,
        lottoNumber: this.selectedLottoModal.lottoNumber,
        lottoBarcode: this.selectedLottoModal.lottoBarcode,
        lottoRefNumber: this.selectedLottoModal.lottoRefNumber,
        price: this.selectedLottoModal.price,
        serviceFee: this.selectedLottoModal.serviceFee,
        qty: this.selectedLottoModal.qty,
        image: this.selectedLottoModal.image,
        imageFullRes:this.selectedLottoModal.imageFullRes,
        qtyMax: this.selectedLottoModal.qtyMax
      })
      this.$store.dispatch('cart/setCART',getStateCart);
      this.showModal=false;
      serviceMain.showSuccessAlert(this,"เพิ่มลงตะกร้าแล้ว")
    },    
  },
  computed: {
    listLottoHaveMaxQty:function(){
      return this.listLottoData.map(e => {
        e.qtyMax =parseInt(e.qty)
        e.qty=e.qtyMax;
        return e
      });
    },
    getCART:function(){
      return this.$store.getters['cart/getCART']
    },
  },
  watch:{


  }
  
}
</script>

<style scoped lang="scss">
.relative{
  position: relative;
}
.image-modal{
  max-width:400px ;
  width: 100%;
  height: auto;
  align-self: flex-start;
  margin: 0px auto !important;
}
.img-lotto{
  cursor: pointer;
  width: 100%;
}
.prev , .next{
  position: absolute;
  background-color:rgba(196, 196, 196, 0.25);
  padding: 40px 5px;
  
  top:50%;
  transform: translateY(-50%);
  text-align: center;
  font-weight: 900;
  font-size: 24px;
  color: rgba(238, 166, 58, 0.75);
  border-radius:9px ;
}
.next{
  right: 20px;
}
.prev{
  left: 20px;
}
.carousel__prev , .carousel__next{
  background-color:lightgray;
  padding: 40px 0px;
  width: 15px;
}
.carousel__next{
  right: -20px;
}
.carousel__prev{
  left: -20px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
input{
  text-align: center;
}
.btn-plus , .btn-minus{
  cursor: pointer;
  border: none;
  background-color: #FFC700;
  
  padding: 0px 15px;
  font-size: 30px;
}
.btn-plus{
  border-radius: 0px 8px 8px 0px; 
}
.btn-minus{
  border-radius: 8px 0px 0px 8px; 
}
.pagination-display{
  background-color: #FFC700;
  border-radius: 12px;
  padding: 0px 10px;
}
.logo-head-left{
  width: 155px;
  position: absolute;
  top: 18px;
  left: -10px;
  transform: translate(0%,-50%);
  z-index: 9;
}
.h1{
  position: relative;
  white-space: nowrap;
}
</style>
